import { useState, useEffect } from "react"

import Footer from "../../component/footer/footer"
import NavBar from "../../component/navbar/navbar"
import styles from "./planning.module.scss"

function PlanningPage() {

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
      
        window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    return(
        <div>
            <NavBar/>
            <section className={styles.firstsection}>
                <h1>planning de la semaine</h1>
                <div className={styles.planningcontainer}>
                    <div className={styles.planninggrid}>
                        <h3 className={styles.lundi}>lundi</h3>
                        <h3 className={styles.mardi}>mardi</h3>
                        <h3 className={styles.mercredi}>mercredi</h3>
                        <h3 className={styles.jeudi}>jeudi</h3>
                        <h3 className={styles.vendredi}>vendredi</h3>
                        <h3 className={styles.samedi}>samedi</h3>
                        
                        <p className={styles.un}>7h</p>
                        <p className={styles.deux}>9h30</p>
                        <p className={styles.trois}>10h</p>
                        <p className={styles.quatre}>11h15</p>
                        <p className={styles.cinq}>12h15</p>
                        <p className={styles.huit}>15h</p>
                        <p className={styles.neuf}>16h</p>
                        <p className={styles.dix}>17h30</p>
                        <p className={styles.onze}>18h45</p>
                        <p className={styles.douze}>20h</p>
                    
                        <div className={styles.lundideux}><h2>wod</h2></div>
                        <div className={styles.lunditrois}><h2>wod</h2></div>
                        <div className={styles.lundiquatre}><h2>open gym</h2></div>
                        <div className={styles.lundisix}><h2>wod</h2></div>
                        <div className={styles.lundionze}><h2>wod</h2></div>
                        <div className={styles.lundidouze}><h2>wod</h2></div>
                        <div className={styles.lunditreize}><h2>wod</h2></div>
                        
                        <div className={styles.mardiquatre}><h2>conditioning</h2></div>
                        <div className={styles.mardicinq}><h2>open gym</h2></div>
                        <div className={styles.mardisix}><h2>wod</h2></div>
                        <div className={styles.mardisept}><h2>open gym</h2></div>
                        <div className={styles.mardionze}><h2>wod</h2></div>
                        <div className={styles.mardidouze}><h2>wod</h2></div>
                        <div className={styles.marditreize}><h2>wod</h2></div>
                        
                        <div className={styles.mercrediquatre}><h2>wod</h2></div>
                        <div className={styles.mercredicinq}><h2>open gym</h2></div>
                        <div className={styles.mercredisix}><h2>wod</h2></div>
                        <div className={styles.mercredisept}><h2>open gym</h2></div>
                        <div className={styles.mercredineuf}><h2>teens</h2></div>
                        <div className={styles.mercredidix}><h2>open gym</h2></div>
                        <div className={styles.mercredionze}><h2>gym</h2></div>
                        <div className={styles.mercredidouze}><h2>wod team</h2></div>
                        <div className={styles.mercreditreize}><h2>wod</h2></div>
                        
                        <div className={styles.jeudideux}><h2>wod</h2></div>
                        <div className={styles.jeuditrois}><h2>wod</h2></div>
                        <div className={styles.jeudiquatre}><h2>open gym</h2></div>
                        <div className={styles.jeudisix}><h2>wod</h2></div>
                        <div className={styles.jeudionze}><h2>wod</h2></div>
                        <div className={styles.jeudidouze}><h2>weightlifting</h2></div>
                        <div className={styles.jeuditreize}><h2>wod</h2></div>
                        
                        <div className={styles.vendrediquatre}><h2>wod</h2></div>
                        <div className={styles.vendredicinq}><h2>open gym</h2></div>
                        <div className={styles.vendredisix}><h2>wod</h2></div>
                        <div className={styles.vendredisept}><h2>open gym</h2></div>
                        <div className={styles.vendredidix}><h2>wod</h2></div>
                        <div className={styles.vendredionze}><h2>wod</h2></div>
                        <div className={styles.vendredidouze}><h2>wod</h2></div>

                        <div className={styles.samediquatre}><h2>wod team</h2></div>
                        <div className={styles.samedicinq}><h2>conditioning</h2></div>
                    </div>
                </div>
            </section>
            <section className={styles.secondesection}>
                <h3 className={width < 800 ? styles.h3phone : width < 600 ? styles.h3phone : undefined}>Le programme de la semaine est composé de trois types de séances d’une durée d’une heure chacune :</h3>
                <div className={styles.wrapper}>
                    <div className={styles.cours}>
                        <h2>le wod :</h2>
                        <p>Entrainement en 3 étapes (Warm Up, Skill, W.O.D.) qui varie chaque jour.</p>
                    </div>
                    <div className={styles.cours}>
                        <h2>open gym :</h2>
                        <p>Accès à la box pour une pratique individuelle sportive. Créneau réservable toute la journée, dans la limite du nombre de places.</p>
                    </div>
                    <div className={styles.cours}>
                        <h2>conditioning :</h2>
                        <p>Cours dédiés à l’endurance.</p>
                    </div>
                    <div className={styles.cours}>
                        <h2>weightlifting :</h2>
                        <p>Travail de force et des mouvements d'haltérophilies.</p>
                    </div>
                </div>
            </section>  
            <section className={styles.thirdsection}>
                <h3>Participation aux cours sur réservation avec l’application peppy</h3>
                <h2>Pour toutes autres informations contactez-nous</h2>
            </section>  
            <Footer/>
        </div>
    )
}

export default PlanningPage